import { create } from "zustand";
import { GameMode } from "../constants/zustand";

interface IState {
  isLogged: boolean;
  token: string;
  level: number;
  gameMode: GameMode;
  switchLogin: (isLogged: boolean) => void;
  addToken: (isLogged: string) => void;
  setLevel: (lvl: number) => void;
  setGameMode: (mode: GameMode) => void;
}

export const appStore = create<IState>((set) => ({
  isLogged: false,
  token: "",
  level: 3,
  gameMode: "mixed",
  switchLogin: (logged: boolean) => set((state) => ({ isLogged: logged })),
  addToken: (newToken: string) => set((state) => ({ token: newToken })),
  setLevel: (lvl: number) => set((state) => ({ level: lvl })),
  setGameMode: (mode: GameMode) => set((state) => ({ gameMode: mode })),
}));