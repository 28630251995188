import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 5px;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.background_purple};
  padding: 10px;
  border-radius: 5px;
  row-gap: 15px;
  min-width: 300px;
  min-height: 350px;

  .inputContainer {
   width: 100%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 30px;
  width: 100%;
  margin-top: auto;
`;
