const headers = {
    "Content-Type": "application/json",
}

const BASE_URL = process.env.REACT_APP_API_URL || '';


export const post = async (url:string, options:any) => {
    const requestUrl = `${BASE_URL}${url}`; // Crea la URL completa para la solicitud
  
    try {
      const response = await fetch(requestUrl, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(options.data),
      }); // Realiza la solicitud HTTP POST
  
      if (!response.ok) {
        throw new Error(response.statusText);
      }
  
      const responseData = await response.json(); // Convierte la respuesta a un objeto JSON
      return responseData; // Devuelve los datos recibidos en la respuesta
    } catch (error) {
      console.error(error); // Maneja los errores de la solicitud HTTP
    }
  };

export const get = async (url:string) => {
    const requestUrl = `${BASE_URL}${url}`; // Crea la URL completa para la solicitud
  
    try {
      const response = await fetch(requestUrl); // Realiza la solicitud HTTP POST
  
      if (!response.ok) {
        throw new Error(response.statusText);
      }
  
      const responseData = await response.json(); // Convierte la respuesta a un objeto JSON
      return responseData; // Devuelve los datos recibidos en la respuesta
    } catch (error) {
      console.error(error); // Maneja los errores de la solicitud HTTP
    }
  };