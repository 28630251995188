import React from "react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { BsSkipForward } from "react-icons/bs";
import { FaGlassWhiskey } from "react-icons/fa";
import * as S from "./card.styled";
import { defaultTheme } from "../../../utils/themes/theme";
import Button from "../../../components/button/button";

interface CardProps {
  name: string;
  description: string;
  onSuccess: () => void;
  onPass: () => void;
  onSkip: () => void;
  onExit: () => void;
}

const Card: React.FC<CardProps> = ({
  name,
  description,
  onPass,
  onSuccess,
  onSkip,
  onExit,
}) => {
  return (
    <S.CardContainer
      $background={defaultTheme.colors.red_100}
      $borderColor={defaultTheme.colors.white}
    >
      <S.CardTitle>{name}</S.CardTitle>
      <S.CardDescription>"{description}"</S.CardDescription>

      <S.ButtonContainer>
        <Button
          background={defaultTheme.colors.red_400}
          backgrounHover={defaultTheme.colors.red_200}
          onClick={onPass}
          className="button"
        >
          <FaGlassWhiskey />
        </Button>
        <Button
          background={defaultTheme.colors.yellow_300}
          backgrounHover={defaultTheme.colors.yellow_100}
          onClick={onSkip}
          className="center-button"
        >
          <BsSkipForward />
        </Button>
        <Button
          background={defaultTheme.colors.green_400}
          backgrounHover={defaultTheme.colors.green_100}
          onClick={onSuccess}
          className="button"
        >
          <FaCheck />
        </Button>
        <S.ExitButtonWrapper>
          <Button
            background={defaultTheme.colors.light_blue}
            backgrounHover={defaultTheme.colors.light_blue_400}
            onClick={onExit}
            className="exit-button"
          >
            <FaTimes />
          </Button>
        </S.ExitButtonWrapper>
      </S.ButtonContainer>
    </S.CardContainer>
  );
};

export default Card;
