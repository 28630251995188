import styled from "styled-components";
import { Container } from "../../../components/card/card.styled";

export const CardContainer = styled(Container)`
  display: flex;
  position: relative;
  flex-direction: column;
  min-width: 250px;
  max-width: 350px;
  min-height: 300px;
  border-radius: 4px;
  padding: 16px;
  box-shadow: 2px 2px 2px ${({ theme }) => theme.colors.shade_purple};
`;

export const CardTitle = styled.p`
  font-size: 35px;
  font-weight: 700;
  align-self: center;
  margin: 0 0 16px;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.primary};
`;

export const CardDescription = styled.p`
  display: flex;
  justify-content: center;
  font-size: 20px;
  line-height: 1.5;
  flex-grow: 1;
  font-family: ${({ theme }) => theme.fonts.primary};
  hyphens: auto;
  font-style: italic;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  padding: 10px;

  .button {
    min-width: 100px;
    height: 40px;
  }
  .center-button {
    min-width: 50px;
    height: 40px;
  }
`;

export const ExitButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0px;
  right: 4px;

  .exit-button{
    padding: 5px;
  }
`;
