import React, { useEffect, useState } from "react";
import * as S from "./manage-page.styled";
import Card from "./card/card";
import axios from "axios";
import { StyledH1 } from "../../utils/fonts/fonts.styled";
import { defaultTheme } from "../../utils/themes/theme";
import { appStore } from "../../hooks/use-zustand";

const ManagePage: React.FC = () => {
  const [challenges, setChallenges] = useState([]);
  const [questions, setQuestions] = useState([]);
  const { token } = appStore();
  const headers = {
    Authorization: `${token}`,
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const fetchedChallenges = await axios.get("/challenges/non-validated");
      setChallenges(fetchedChallenges.data);
      const fetchedQuestions = await axios.get("/questions/non-validated");
      setQuestions(fetchedQuestions.data);
    } catch (error) {
      console.error("Error al obtener los challenges:", error);
    }
  };

  function handleDelete(type: string, id: number) {
    if (type === "challenge") {
      axios
        .delete("/challenges", {
          data: { id },
          headers,
        })
        .then((data: any) => {
          fetchData();
        })
        .catch((error: any) => console.error(error));
    }
    if (type === "questions") {
      axios
        .delete("/questions", {
          data: { id },
          headers,
        })
        .then((data: any) => {
          fetchData();
        })
        .catch((error: any) => console.error(error));
    }
  }

  function handleEdit(type: string, id: number, newValue: string) {
    if (type === "challenge") {
      axios
        .put(
          "/challenges",
          {
            data: { id, description: newValue },
          },
          { headers }
        )
        .then((data: any) => {
          fetchData();
        })
        .catch((error: any) => console.error(error));
    }
    if (type === "questions") {
      axios
        .put(
          "/questions",
          {
            data: { id, description: newValue },
          },
          { headers }
        )
        .then((data: any) => {
          fetchData();
        })
        .catch((error: any) => console.error(error));
    }
  }

  function handleValidate(type: string, id: number) {
    if (type === "challenge") {
      axios
        .post(
          "/challenges/validate",
          {
            data: { id },
          },
          { headers }
        )
        .then((data: any) => {
          fetchData();
        })
        .catch((error: any) => console.error(error));
    }
    if (type === "questions") {
      axios
        .post(
          "/questions/validate",
          {
            data: { id },
          },
          { headers }
        )
        .then((data: any) => {
          fetchData();
        })
        .catch((error: any) => console.error(error));
    }
  }

  return (
    <S.PageContainer>
      {challenges && (
        <S.Column
          $background={defaultTheme.colors.light_blue}
          $borderColor={defaultTheme.colors.white}
        >
          <StyledH1>Challenges/Retos</StyledH1>
          <S.ElementsContainer>
            {challenges.map((element: any, key) => (
              <Card
                key={`challenge-${key}`}
                onValidate={() => handleValidate("challenge", element.id)}
                onDelete={() => handleDelete("challenge", element.id)}
                onEdit={(newValue: string) =>
                  handleEdit("challenge", element.id, newValue)
                }
                element={element}
              />
            ))}
          </S.ElementsContainer>
        </S.Column>
      )}
      {questions && (
        <S.Column
          $background={defaultTheme.colors.yellow_100}
          $borderColor={defaultTheme.colors.white}
        >
          <StyledH1>Questions/Preguntas</StyledH1>
          <S.ElementsContainer>
            {questions.map((element: any, key) => (
              <Card
                key={`question-${key}`}
                onValidate={() => handleValidate("questions", element.id)}
                onDelete={() => handleDelete("questions", element.id)}
                onEdit={(newValue: string) =>
                  handleEdit("questions", element.id, newValue)
                }
                element={element}
              />
            ))}
          </S.ElementsContainer>
        </S.Column>
      )}
    </S.PageContainer>
  );
};

export default ManagePage;
