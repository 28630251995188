import React from "react";
import * as S from "./delete-user-moda.styled";
import { defaultTheme } from "../../../utils/themes/theme";
import Button from "../../../components/button/button";
import { StyledH2, StyledText } from "../../../utils/fonts/fonts.styled";
import Modal from "../../../components/modal/modal";
import { IGameUser } from "../../../constants/user";

interface IProps {
  user: IGameUser;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteUserModal: React.FC<IProps> = ({ user, onClose, onDelete }) => {
  return (
    <Modal onClose={onClose}>
      <S.Container>
        <StyledH2>{`Quieres borrar a ${user.name}?`}</StyledH2>
        <S.ButtonContainer>
          <Button className="action-button" onClick={onDelete} background={defaultTheme.colors.green_100}>
            <StyledText>Cancelar</StyledText>
          </Button>

          <Button className="action-button" onClick={onDelete} background={defaultTheme.colors.red_400}>
            <StyledText>Borrar</StyledText>
          </Button>
        </S.ButtonContainer>
      </S.Container>
    </Modal>
  );
};

export default DeleteUserModal;
