import React, { useState } from "react";
import * as S from "./login.styled";
import { appStore } from "../../hooks/use-zustand";
import { useNavigate } from "react-router";
import { defaultTheme } from "../../utils/themes/theme";
import Button from "../../components/button/button";
import { StyledH2, StyledText } from "../../utils/fonts/fonts.styled";
import { post } from "../../services/fetch-functions";

const Login: React.FC = () => {
  const { switchLogin, addToken } = appStore();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (userName && password) {
      post("/users/login", { data: { user: userName, pass: password } })
        .then((data) => {
          if (data && data.login && data.token) {
            addToken(data.token);
            switchLogin(true);
            navigate(`/manage`);
          } else {
            setShowError(true);
            setPassword("");
          }
        })
        .catch((err: any) => {
          setShowError(true);
          console.log("Algo ha salido mal:", err);
        });
    }
  };

  return (
    <S.LoginContainer>
      <S.LoginForm
        onSubmit={handleSubmit}
        $background={defaultTheme.colors.red_200}
        $borderColor={defaultTheme.colors.white}
      >
        <S.FormField>
          <StyledText>Username:</StyledText>
          <S.Input
            type="text"
            value={userName}
            onChange={(event) => setUserName(event.target.value)}
          />
        </S.FormField>
        <S.FormField>
          <StyledText>Password:</StyledText>
          <S.Input
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </S.FormField>
        {showError && (
          <S.FormField>
            <StyledText color={defaultTheme.colors.red}>
              Usuario o contraseña incorrecta
            </StyledText>
          </S.FormField>
        )}
        <S.ButtonContainer>
          <Button
            className="button"
            background={defaultTheme.colors.green_100}
            type="submit"
            onClick={handleSubmit}
          >
            <StyledH2>Log In</StyledH2>
          </Button>
        </S.ButtonContainer>
      </S.LoginForm>
    </S.LoginContainer>
  );
};

export default Login;
