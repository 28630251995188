import React from "react";
import * as S from "./card.styled";

interface Props {
  background: string;
  borderColor?: string;
  className?: string;
  children: any;
}

export const Card: React.FC<Props> = ({
  background,
  className,
  borderColor,
  children,
}) => {
  return (
    <S.Container
      className={className}
      $background={background}
      $borderColor={borderColor}
    >
      {children}
    </S.Container>
  );
};
