import styled from "styled-components";
import { Container } from "../card/card.styled";

export const ListContainer = styled.div`
  display: flex;
  column-gap: 10px;
  justify-content: center;
`;

export const Item = styled(Container)`
  display: inline-block;
  padding: 10px;
  border: 2px solid ${({ theme }) => theme.colors.black};
  cursor: pointer;
`;
