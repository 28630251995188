import React from "react";
import * as S from "./button.styled";

interface IProps {
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  className?: string;
  background: string;
  backgrounHover?: string;
  borderColor?: string;
  color?: string;
  colorHover?: string;
  children: React.ReactNode;
  id?: string;
  disabled?: boolean;
}

const Button: React.FC<IProps> = ({
  children,
  className,
  onClick,
  type,
  borderColor,
  backgrounHover,
  background,
  color,
  colorHover,
  id,
  disabled,
}) => {
  return (
    <S.ButtonStyled
      id={id}
      onClick={onClick}
      type={type}
      $backgrounHover={backgrounHover}
      $background={background}
      $color={color}
      $colorHover={colorHover}
      disabled={disabled}
      className={className}
      $borderColor={borderColor}
    >
      {children}
    </S.ButtonStyled>
  );
};

export default Button;
