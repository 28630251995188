import styled from "styled-components";
import { Container } from "../../../components/card/card.styled";

export const UserContainer = styled(Container)`
  display: flex;
  flex-grow: 1;
  align-items: center;
  column-gap: 10px;
  padding: 10px 8px;
  max-height: 20px;
  
  cursor: pointer;

  > svg {
    flex-shrink: 0;
  }

  .name {
    flex-grow: 1;
  }

  :hover{
    opacity: 0.7;
  }
`;

export const BubbleIcon = styled(Container)`
  display: flex;
  border-radius: 50%;
  width: auto;
  padding: 3px;
`;
