import styled from "styled-components";
import { Container } from "../../../components/card/card.styled";

export const CardContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 200px;
  border: 2px solid ${(props) => props.theme.colors.black};
  padding: 16px;
`;

export const Description = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  justify-content: center;

  > p {
    text-align: center;
    margin-bottom: 16px;
  }
`;

export const TextArea = styled.textarea`
  font-size: 16px;
  font-family: inherit;
  padding: 0.25em 0.5em;
  background-color: #fff;
  border-radius: 4px;
  width: 80%;
  height: 80%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  column-gap: 15px;
  width: 100%;
  .button {
    cursor: pointer;
    min-width: 60px;
    height: 40px;
    font-size: 0.8;
    flex: 1;
  }
`;
