import React, { useState } from "react";
import * as S from "./card.styled";
import { ICard } from "../manage-page-constants";
import { StyledH4, StyledText } from "../../../utils/fonts/fonts.styled";
import { defaultTheme } from "../../../utils/themes/theme";
import Button from "../../../components/button/button";
import TextArea from "../../../components/textarea/textArea";

interface IProps {
  element: ICard;
  onDelete: () => void;
  onValidate: () => void;
  onEdit: (newValue: string) => void;
  key?: string;
}

const Card: React.FC<IProps> = ({
  element,
  key,
  onDelete,
  onValidate,
  onEdit,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [text, setText] = useState<string>(element.description || "");

  function handleDeleteButton() {
    if (editMode) {
      setText(element.description);
      setEditMode(!editMode);
    } else {
      onDelete();
    }
  }

  function handleAcceptButton() {
    if (editMode) {
      onEdit(text);
      setEditMode(!editMode);
    } else {
      onValidate();
    }
  }

  return (
    <S.CardContainer key={key} $background={defaultTheme.colors.purple_100}>
      <S.Description>
        {editMode ? (
          <TextArea
            onBlur={(e) => setText(e.target.value)}
            initialValue={text}
          />
        ) : (
          <StyledText>{element.description}</StyledText>
        )}
      </S.Description>

      <S.ButtonContainer>
        <Button
          background={defaultTheme.colors.red_200}
          backgrounHover={defaultTheme.colors.red_100}
          onClick={handleDeleteButton}
          className="button"
        >
          {!editMode ? (
            <StyledH4>Borrar</StyledH4>
          ) : (
            <StyledH4>Cancelar</StyledH4>
          )}
        </Button>

        {!editMode && (
          <Button
            background={defaultTheme.colors.yellow_300}
            backgrounHover={defaultTheme.colors.yellow_200}
            onClick={() => setEditMode(!editMode)}
            className="button"
          >
            <StyledH4>Editar</StyledH4>
          </Button>
        )}

        <Button
          background={defaultTheme.colors.light_blue}
          backgrounHover={defaultTheme.colors.light_blue_400}
          onClick={handleAcceptButton}
          className="button"
        >
          {!editMode ? <StyledH4>Validar</StyledH4> : <StyledH4>Ok</StyledH4>}
        </Button>
      </S.ButtonContainer>
    </S.CardContainer>
  );
};

export default Card;
