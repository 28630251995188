import styled from "styled-components";
import { Container } from "../card/card.styled";

export const ModalBackdrop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  );
  z-index: 100;
`;

export const ModalContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 30px 15px 8px 15px
`;

export const CrossContainer = styled(Container)`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 4px;
  padding: 2px;
  cursor: pointer;
`;
