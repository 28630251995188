import styled, { css } from "styled-components";

export const ButtonStyled = styled.button<{
  $background?: string;
  $backgrounHover?: string;
  $color?: string;
  $colorHover?: string;
  $borderColor?: string;
}>`
  display: flex;
  position: relative;
  flex-grow: 1;
  padding: 10px 20px;
  text-transform: uppercase;
  color: ${(props) => props.$color};
  text-shadow: none;
  transition: 0.5s ease;
  user-select: none;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 2px solid;
  box-shadow: 4px 6px 0px 0px ${({ $borderColor }) => $borderColor};
  box-sizing: border-box;

  ${({ $background }) =>
    $background &&
    css`
      background-color: ${$background};
    `};

  &:hover,
  &:focus {
    color: ${(props) => props.$colorHover};
    ${({ $backgrounHover }) =>
      $backgrounHover
        ? css`
            background-color: ${$backgrounHover};
          `
        : css`
            opacity: 0.7;
          `};
  }
`;
