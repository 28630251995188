import styled, { css } from "styled-components";

export const Container = styled.div<{ $width?: string }>`
  img {
    width: 100%;
    ${({ $width }) =>
      $width &&
      css`
        width: ${$width};
      `};
  }
`;
