import styled, { css } from "styled-components";

const fontPublicSans = css`
  font-family: "Public Sans", sans-serif;
`;
export const fontLexendMega = css`
  font-family: "Lexend Mega", sans-serif;
`;
const fontArchivoBlack = css`
  font-family: "Archivo Black", sans-serif;
`;
interface IProps {
  $color?: string;
}

export const StyledH4 = styled.h4<IProps>`
  color: ${({ theme, color }) => (color ? color : theme.colors.black)};

  font-size: 0.8;
  margin: 0;
  ${fontPublicSans}
`;

export const StyledH3 = styled.h3<IProps>`
  color: ${({ theme, color }) => (color ? color : theme.colors.black)};

  font-size: 1.2rem;
  margin: 0;
  ${fontPublicSans}
`;

export const StyledH2 = styled.h2<IProps>`
  color: ${({ theme, color }) => (color ? color : theme.colors.black)};

  font-size: 1.5rem;
  margin: 0;
  font-weight: 700;
  ${fontArchivoBlack}
`;

export const StyledH1 = styled.h1<IProps>`
  color: ${({ theme, color }) => (color ? color : theme.colors.black)};

  font-size: 2rem;
  margin: 0;
  font-weight: 700;
  ${fontArchivoBlack}
`;

export const StyledText = styled.p<IProps>`
  color: ${({ theme, color }) => (color ? color : theme.colors.black)};
  margin: 0;
  ${fontLexendMega}
`;
