import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    align-items: center;
    height: 100%;

    .add-player{
        height: 300px;
    }
`;
export const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 28px;
    height: max-content;
    justify-content: center;
`;

