import styled from "styled-components";
import { defaultTheme } from "../../utils/themes/theme";
import { ITextAreaProps } from "../../constants/components";
import { fontLexendMega } from "../../utils/fonts/fonts.styled";

export const StyledTextArea = styled.textarea<ITextAreaProps>`
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "80px"};
  padding: ${(props) => props.padding || "8px"};
  font-size: ${(props) => props.fontSize || "16px"};
  border: ${(props) => props.border || "2px solid"};
  border-radius: ${(props) => props.borderRadius || "8px"};
  border-color: ${(props) =>
    props.borderColor || `${defaultTheme.colors.black}`};
  box-sizing: border-box;
  box-shadow: 4px 6px 0px 0px;
  max-height: 120px;
  max-width: 100%;
  ${fontLexendMega}
`;
