import React, { useState } from "react";
import * as S from "./penalty-modal.styles";
import Button from "../../../components/button/button";
import { defaultTheme } from "../../../utils/themes/theme";
import { StyledH2 } from "../../../utils/fonts/fonts.styled";

interface IProps {
  penalty: number;
  onClose: () => void;
}

const PenaltyModal: React.FC<IProps> = ({ onClose, penalty }) => {
  return (
    <S.PenaltyContainer>
      <S.ModalContainer
        $background={defaultTheme.colors.light_blue}
        $borderColor={defaultTheme.colors.white}
      >
        <S.Description>La penalización es de : {penalty} sorbo/s</S.Description>
        <Button className="ok-button" background={defaultTheme.colors.green_200} onClick={onClose}>
          <StyledH2>Hecho!</StyledH2>
        </Button>
      </S.ModalContainer>
    </S.PenaltyContainer>
  );
};

export default PenaltyModal;
