import React, { useState } from "react";
import Button from "../../../components/button/button";
import * as S from "./add-questions-modal.styled";
import { Cross } from "@styled-icons/entypo/Cross";
import { defaultTheme } from "../../../utils/themes/theme";
import { StyledH2, StyledH3 } from "../../../utils/fonts/fonts.styled";
import TextArea from "../../../components/textarea/textArea";
import { post } from "../../../services/fetch-functions";
import { modeEnum } from "../../../utils/interfaces/enums";


interface IProps {
  sessionId: string;
  mode?: modeEnum;
  onClose: () => void;
  onSubmit?: (data: { question?: string; challenge?: string }) => void;
}

const AddQuestionsModal: React.FC<IProps> = ({
  onClose,
  onSubmit,
  sessionId,
  mode = modeEnum.mix,
}) => {
  const [challenge, setChallenge] = useState("");
  const [question, setQuestion] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    if (challenge) {
      await post("/challenges/add", {
        data: { challenge, sessionId },
      }).catch((error: any) => console.error(error));
    }
    if (question) {
      await post("/questions/add", {
        data: { question, sessionId },
      }).catch((error: any) => console.error(error));
    }
    if ((question || challenge) && onSubmit) {
      onSubmit({ question, challenge });
    }
    setIsLoading(false);
    onClose();
  };

  return (
    <S.PageContainer>
      <S.ModalContainer
        $background={defaultTheme.colors.light_blue_200}
        $borderColor={defaultTheme.colors.white}
      >
        <S.CrossIcon onClick={() => onClose()}>
          <Cross />
        </S.CrossIcon>
        <S.FormContainer>
          <S.InputsContainer>
            {mode === modeEnum.mix ||
              (mode === modeEnum.challengues && (
                <>
                  <StyledH3>Reto:</StyledH3>
                  <TextArea
                    onBlur={(e) => setChallenge(e.target.value)}
                    initialValue={challenge}
                  />
                </>
              ))}
            {mode === modeEnum.mix ||
              (mode === modeEnum.questions && (
                <>
                  <StyledH3>Pregunta:</StyledH3>
                  <TextArea
                    onBlur={(e) => setQuestion(e.target.value)}
                    initialValue={question}
                  />
                </>
              ))}
          </S.InputsContainer>

          <S.ButtonContainer>
            <Button
              className="send-button"
              background={defaultTheme.colors.green_200}
              onClick={() => handleSubmit()}
              disabled={isLoading}
            >
              <StyledH2>{isLoading ? "Enviando" : "Enviar"}</StyledH2>
            </Button>
          </S.ButtonContainer>
        </S.FormContainer>
      </S.ModalContainer>
    </S.PageContainer>
  );
};

export default AddQuestionsModal;
