import React, { useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import * as S from "./qr-code.styled";
import { QRModal } from "./qr-modal/qrModal";

interface Props {
  link: string;
}


export const QRCode: React.FC<Props> = ({ link }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <S.Container>
      <S.QRContainer onClick={() => setModalIsOpen(true)}>
        <QRCodeSVG value={link} />
      </S.QRContainer>
      <S.Title>Scan me!</S.Title>


      {modalIsOpen  && (
        <QRModal
          link={link}
          onClose={() => setModalIsOpen(false)}
        />
      )}
    </S.Container>
  );
};
