interface ITheme {
  colors: {
    light_blue: string;
    light_blue_400: string;
    light_blue_300: string;
    light_blue_200: string;
    light_blue_100: string;

    green: string;
    green_400: string;
    green_300: string;
    green_200: string;
    green_100: string;

    purple: string;
    purple_400: string;
    purple_300: string;
    purple_200: string;
    purple_100: string;

    red: string;
    red_400: string;
    red_300: string;
    red_200: string;
    red_100: string;

    yellow: string;
    yellow_400: string;
    yellow_300: string;
    yellow_200: string;
    yellow_100: string;

    dark_blue: string;

    black: string;
    white: string;
  };
  fonts: {
    primary: string;
    secondary: string;
    heading: string;
  };
  fontSizes: {
    small: string;
    medium: string;
    large: string;
  };
}

export const defaultTheme: ITheme = {
  colors: {
    dark_blue: "#3300FF",

    light_blue: "#7df9ff",
    light_blue_400: "#69d2e7",
    light_blue_300: "#87ceeb",
    light_blue_200: "#a7dbd8",
    light_blue_100: "#daf5f0",

    green: "#2FFF2F",
    green_400: "#7FBC8C",
    green_300: "#90EE90",
    green_200: "#BAFCA2",
    green_100: "#B5D2AD",

    purple: "#FF00F5",
    purple_400: "#FF69B4",
    purple_300: "#FFB2EF",
    purple_200: "#FFC0CB",
    purple_100: "#FCDFFF",

    red: "#FF4911",
    red_400: "#FF6B6B",
    red_300: "#FF7A5C",
    red_200: "#FFA07A",
    red_100: "#F8D6B3",

    yellow: "#FFFF00",
    yellow_400: "#E3A018",
    yellow_300: "#F4D738",
    yellow_200: "#FFDB58",
    yellow_100: "#FDFD96",

    black: "#1e1e1e",
    white: "#FFFFFF",
  },
  fonts: {
    primary: "'Public Sans', sans-serif",
    secondary: "'Archivo Black', sans-serif",
    heading: "'Lexend Mega', sans-serif",
  },
  fontSizes: {
    small: "14px",
    medium: "16px",
    large: "18px",
  },
};
