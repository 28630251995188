import styled from "styled-components";
import { Container } from "../../components/card/card.styled";

export const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
`;

export const ElementsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;

  justify-items: center;
  padding-bottom: 10px;
`;

export const Column = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  width: 80%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 8px 10px;
`;
