import React, { useState } from "react";
import * as S from "./edit-user-modal.styled";
import { defaultTheme } from "../../../utils/themes/theme";
import Button from "../../../components/button/button";
import { StyledH2, StyledText } from "../../../utils/fonts/fonts.styled";
import Modal from "../../../components/modal/modal";
import Input from "../../../components/input/input";
import { IGameUser } from "../../../constants/user";

interface IProps {
  user: IGameUser;
  onClose: () => void;
  onSave: (user: IGameUser) => void;
}

const EditUserModal: React.FC<IProps> = ({ user, onClose, onSave }) => {
  const [newUser, setNewUser] = useState({ ...user });

  return (
    <Modal onClose={onClose}>
      <S.Container>
        <StyledH2>Cambia tu nombre:</StyledH2>
        <Input
          onBlur={(e) => setNewUser({ ...newUser, name: e })}
          placeholder={user.name}
        ></Input>
        <S.ButtonContainer>
          <Button
            onClick={() => onSave(newUser)}
            background={defaultTheme.colors.green_200}
          >
            <StyledText>Guardar</StyledText>
          </Button>
        </S.ButtonContainer>
      </S.Container>
    </Modal>
  );
};

export default EditUserModal;
