import React from "react";
import { useParams } from "react-router";
import { QRCode } from "../../components/qrcode/qr-code";
import * as S from "./inscription-page.styled";
import UserList from "./user-list/user-list";
import ImageLogo from "../../components/logoImage/logoImage";

const InscriptionPage: React.FC = () => {
  const { sessionId } = useParams();

  return (
    <S.Container>
      <ImageLogo isWhite width="200px" />
      <UserList id={sessionId || ""} />
      <QRCode link={window.location.href} />
    </S.Container>
  );
};

export default InscriptionPage;
