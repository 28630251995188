import React, { useState } from "react";
import { FormContainer, Container, ButtonContainer } from "./home-page.styled";
import { useNavigate } from "react-router-dom";
import { post } from "../../services/fetch-functions";
import Input from "../../components/input/input";
import Button from "../../components/button/button";
import { Card } from "../../components/card/card";
import { defaultTheme } from "../../utils/themes/theme";
import ImageLogo from "../../components/logoImage/logoImage";
import { StyledH3, StyledH2 } from "../../utils/fonts/fonts.styled";
import SelectableItemList from "../../components/selectable-item-list/selectable-item-list";


const HomePage: React.FC = () => {
  const [nombreSesion, setNombreSesion] = useState("");
  const [gameType, setGameType] = useState(2);
  const navigate = useNavigate();

  const gameModeTypes = [
    { id: 2, name: "Mixto", type: "mixed" },
    { id: 0, name: "Sólo preguntas", type: "questions" },
    { id: 1, name: "Sólo pruebas", type: "challenges" },
  ];

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (nombreSesion !== "") {
      post("/session", { data: { name: nombreSesion, game_type_id:gameType, game_level_id: 2 } })
        .then((data) => {
          navigate("/session/" + data.id);
        })
        .catch((err: string) => {
          console.log("Algo ha salido mal:", err);
        });
    }
  };

  function handleOnChangeGameType(id: number) {
    setGameType(id);
  }

  return (
    <Container>
      <ImageLogo width="200px" isWhite />
      <Card
        background={defaultTheme.colors.red_100}
        borderColor={defaultTheme.colors.white}
      >
        <FormContainer onSubmit={handleSubmit}>
          <StyledH3>¿Qué celebramos?</StyledH3>
          <div className="inputContainer">
            <Input
              type="text"
              defaultValue={nombreSesion}
              onBlur={(name) => setNombreSesion(name)}
              placeholder="Nombre de la sesión"
            />
          </div>
          <StyledH3>Modo de juego</StyledH3>

          <SelectableItemList
            onChange={handleOnChangeGameType}
            items={gameModeTypes}
            selected={gameType}
          />
          {/*           <StyledH3>Nivel de las preguntas</StyledH3>
          <ToggleButton /> */}
          <ButtonContainer>
            <Button
              id="create-session-button"
              background={defaultTheme.colors.red_300}
              backgrounHover={defaultTheme.colors.red}
              color={defaultTheme.colors.black}
              borderColor={defaultTheme.colors.black}
              type="submit"
            >
              <StyledH2>Crear sesión</StyledH2>
            </Button>
          </ButtonContainer>
        </FormContainer>
      </Card>
    </Container>
  );
};

export default HomePage;
