import React, { useState, useEffect } from "react";
import { QRCodeSVG } from "qrcode.react";
import * as S from "./qrModal.styled";

interface Props {
  link: string;
  onClose: () => void;
}

export const QRModal: React.FC<Props> = ({ link, onClose }) => {
  const [maxWidth, setMaxWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setMaxWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <S.Container onClick={onClose}>
      <S.ModalContainer>
        <QRCodeSVG value={link} size={maxWidth < 400 ? maxWidth - 40 : 400} />
      </S.ModalContainer>
    </S.Container>
  );
};
