import React from "react";
import whiteLogo from "../../images/white_beerOdyssey.png";
import blackLogo from "../../images/black_beerOdyssey.png";
import * as S from "./logoImage.styled";

interface IProps {
  width?: string;
  isWhite?: boolean;
}

const ImageLogo: React.FC<IProps> = ({ isWhite, width }) => {
  return (
    <S.Container $width={width}>
      <img src={isWhite ? whiteLogo : blackLogo} alt="Logo" />
    </S.Container>
  );
};

export default ImageLogo;
