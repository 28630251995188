import React, { useRef, useState } from "react";
import * as S from "./user-bubble.styled";
import { AiOutlineUser, AiTwotoneEdit } from "react-icons/ai";
import { FaTrashAlt } from "react-icons/fa";
import { defaultTheme } from "../../../utils/themes/theme";
import { StyledText } from "../../../utils/fonts/fonts.styled";
import useOutsideClick from "../../../hooks/useOutsideClick";

interface IProps {
  user: {
    id: string;
    name: string;
    editUser: ()=>void;
    deleteUser: ()=>void;
  };
}

const UserBubble: React.FC<IProps> = ({ user }) => {
  const [isActive, setIsActive] = useState(false);

  const userRef = useRef(null);

  useOutsideClick(userRef, () => {
    setIsActive(false);
  });

  return (
    <S.UserContainer
      $background={defaultTheme.colors.red_200}
      $borderColor={defaultTheme.colors.black}
      onClick={() => setIsActive(!isActive)}
      ref={userRef}
    >
      <AiOutlineUser />
      <StyledText className="name">{user.name}</StyledText>
      {isActive && (
        <>
          <div onClick={user.editUser} id="hola">
            <S.BubbleIcon $background={defaultTheme.colors.red_100}>
              <AiTwotoneEdit />
            </S.BubbleIcon>
          </div>
          <S.BubbleIcon
            onClick={user.deleteUser}
            $background={defaultTheme.colors.red_100}
          >
            <FaTrashAlt />
          </S.BubbleIcon>
        </>
      )}


    </S.UserContainer>
  );
};

export default UserBubble;
