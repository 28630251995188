import { IGameUser } from "../../constants/user";
import { get } from "../../services/fetch-functions";
import { useState, useEffect } from "react";

enum GameMode {
  Questions,
  Challenges,
  Mixed,
}

interface IGameConfig {
  gameLevel: number;
  gameType: number;
  id: number;
}

const useGameFetching = (sessionId: string | undefined) => {
  const [challenges, setChallenges] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [generalChallenges, setGeneralChallenges] = useState([]);
  const [generalQuestions, setGeneralQuestions] = useState([]);
  const [users, setUsers] = useState<IGameUser[]>([]);
  const [gameConfig, setGameConfig] = useState<IGameConfig>();

  useEffect(() => {
    if (sessionId) {
      fetchConfig();
      fetchUsers();
    }
  }, [sessionId]);

  useEffect(() => {
    if (gameConfig !== undefined) {
      if(gameConfig.gameType === 0){
      }
      switch (gameConfig.gameType) {
        case GameMode.Questions:
          fetchQuestions();
          fetchGeneralQuestions();
          break;
        case GameMode.Challenges:
          fetchChallenges();
          fetchGeneralChallenges();
          break;
        default:
          fetchChallenges();
          fetchQuestions();
          fetchGeneralChallenges();
          fetchGeneralQuestions();
          break;
      }
    }
  }, [gameConfig]);

  async function fetchChallenges() {
    const data = await get(`/challenges/${sessionId}`);
    setChallenges(data);
  }

  async function fetchQuestions() {
    const data = await get(`/questions/${sessionId}`);
    setQuestions(data);
  }

  async function fetchGeneralChallenges() {
    const data = await get(`/challenges`);
    setGeneralChallenges(data);
  }

  async function fetchGeneralQuestions() {
    const data = await get(`/questions/`);
    setGeneralQuestions(data);
  }

  async function fetchUsers() {
    const data = await get(`/users/${sessionId}`);
    setUsers(data);
  }
  async function fetchConfig() {
    const data = await get(`/session/${sessionId}`);
    setGameConfig(data[0]);
  }

  return {
    users,
    questions,
    generalChallenges,
    generalQuestions,
    challenges,
  };
};

export default useGameFetching;
