import React from "react";
import * as S from "./modal.styled";
import { defaultTheme } from "../../utils/themes/theme";
import { RxCross2 } from "react-icons/rx";

interface IProps {
  children: React.ReactNode;
  onClose: () => void;
}

const Modal: React.FC<IProps> = ({ onClose, children }) => {
  return (
    <S.ModalBackdrop onClick={(e) => e.preventDefault()}>
      <S.ModalContainer
        $background={defaultTheme.colors.light_blue}
        $borderColor={defaultTheme.colors.white}
      >
        <S.CrossContainer
          onClick={onClose}
          $background={defaultTheme.colors.green_200}
        >
          <RxCross2 />
        </S.CrossContainer>
        {children}
      </S.ModalContainer>
    </S.ModalBackdrop>
  );
};

export default Modal;
