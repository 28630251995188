import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: black;
  padding: 6px 6px 8px 6px;
  border-radius: 8px;
  border: 1px;
`;
export const QRContainer = styled.div`
  display: flex;
  background-color: whitesmoke;
  padding: 4px;
  cursor: pointer;
`;

export const Title = styled.h1`
  color: whitesmoke;
  text-transform: capitalize;
  margin: 0;
`;
