import React, { useState } from "react";
import { post } from "../../../services/fetch-functions";
import Input from "../../../components/input/input";
import Button from "../../../components/button/button";
import * as S from "./add-player-modal.styled";
import { Cross } from "@styled-icons/entypo/Cross";
import { defaultTheme } from "../../../utils/themes/theme";
import { StyledH2, StyledH3 } from "../../../utils/fonts/fonts.styled";
import TextArea from "../../../components/textarea/textArea";

interface IProps {
  sessionId: string;
  onClose: () => void;
}

const AddPlayerModal: React.FC<IProps> = ({ onClose, sessionId }) => {
  const [question, setQuestion] = useState("");
  const [challenge, setChallenge] = useState("");
  const [nickname, setNickname] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    if (nickname && challenge && question) {
      setIsLoading(true);
      post("/users/add-player", {
        data: { nickname, challenge, question, sessionId },
      })
        .then((data: any) => {
          setIsLoading(false);
          onClose();
        })
        .catch((error: any) => console.error(error));
    }
  };

  return (
    <S.PageContainer>
      <S.ModalContainer
        $background={defaultTheme.colors.light_blue_200}
        $borderColor={defaultTheme.colors.white}
      >
        <S.CrossIcon onClick={() => onClose()}>
          <Cross />
        </S.CrossIcon>

        <S.FormContainer>
          <S.InputsContainer>
            <StyledH3>Nombre o apodo:</StyledH3>
            <Input
              type="text"
              defaultValue={nickname}
              onBlur={(e) => setNickname(e)}
            />
            <StyledH3>Reto:</StyledH3>
            <TextArea
              initialValue={challenge}
              onBlur={(e) => setChallenge(e.target.value)}
            />
            <StyledH3>Pregunta:</StyledH3>
            <TextArea
              initialValue={question}
              onBlur={(e) => setQuestion(e.target.value)}
            />
          </S.InputsContainer>

          <S.ButtonContainer>
            <Button
              className="send-button"
              background={defaultTheme.colors.green_200}
              onClick={() => handleSubmit()}
              disabled={isLoading}
            >
              <StyledH2>{isLoading ? "Enviando" : "Enviar"}</StyledH2>
            </Button>
          </S.ButtonContainer>
        </S.FormContainer>
      </S.ModalContainer>
    </S.PageContainer>
  );
};

export default AddPlayerModal;
