import React from "react";
import { useParams } from "react-router";
import * as S from "./add-player.styled";
import Button from "../../components/button/button";
import { defaultTheme } from "../../utils/themes/theme";
import { Card } from "../../components/card/card";
import { useModal } from "../../hooks/useModal";
import AddPlayerModal from "../inscription-page/add-player-modal/add-player-modal";
import AddQuestionsModal from "../inscription-page/add-questions-modal/add-questions-modal";
import { modeEnum } from "../../utils/interfaces/enums";

const AddPlayerPage: React.FC = () => {
  const { sessionId } = useParams();
  const { showModal, setShowModal } = useModal();
  const { showModal: showQuestionModal, setShowModal: setShowQuestionModal } =
    useModal();
  const { showModal: showChallenges, setShowModal: setShowChallengesModal } =
    useModal();

  return (
    <S.Container>
      <Card
        background={defaultTheme.colors.red_200}
        borderColor={defaultTheme.colors.white}
        className="add-player"
      >
        <S.ButtonContainer>
          <Button
            background={defaultTheme.colors.green_400}
            backgrounHover={defaultTheme.colors.green_200}
            onClick={() => setShowModal(true)}
          >
            Añadir usuario
          </Button>
          <Button
            background={defaultTheme.colors.light_blue_400}
            backgrounHover={defaultTheme.colors.light_blue_200}
            onClick={() => setShowQuestionModal(true)}
          >
            Añadir más preguntas
          </Button>
          <Button
            background={defaultTheme.colors.purple_400}
            backgrounHover={defaultTheme.colors.purple_200}
            onClick={() => setShowChallengesModal(true)}
          >
            Añadir nás retos
          </Button>
        </S.ButtonContainer>
      </Card>
      {showModal && sessionId && (
        <AddPlayerModal
          sessionId={sessionId}
          onClose={() => setShowModal(false)}
        />
      )}

      {showChallenges && sessionId && (
        <AddQuestionsModal
          sessionId={sessionId}
          onClose={() => setShowChallengesModal(false)}
          mode={modeEnum.challengues}
        />
      )}
      {showQuestionModal && sessionId && (
        <AddQuestionsModal
          sessionId={sessionId}
          onClose={() => setShowQuestionModal(false)}
          mode={modeEnum.questions}
        />
      )}
    </S.Container>
  );
};

export default AddPlayerPage;
