import styled from "styled-components";
import { Container } from "../../../components/card/card.styled";

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
`;

export const ModalContainer = styled(Container)`
  position: absolute;
  width: 335px;
  height: 500px;
  border-radius: 8px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  height: 100%;
  box-sizing: border-box;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  h3 {
    padding-top: 14px;
    padding-bottom: 10px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  height: 40px;
  width: 100%;
  margin-top: 10px;
  justify-content: center;
  align-items: center;

  .send-button {
    height: 100%;
  }
`;
export const CrossIcon = styled.div`
  position: absolute;
  right: 0px;
  width: 30px;
  cursor: pointer;
`;
