import styled, { css } from "styled-components";

export const Container = styled.div<{
  $background?: string;
  $borderColor?: string;
}>`
  position: relative;
  border-radius: 8px;
  border: 2px solid;
  box-shadow: 4px 6px 0px 0px ${({ $borderColor }) => $borderColor};
  width: fit-content;

  ${({ $background }) =>
    $background &&
    css`
      background-color: ${$background};
    `};

  ${({ $borderColor }) =>
    $borderColor &&
    css`
      border-color: ${$borderColor};
    `};
`;
