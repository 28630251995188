import React, { ReactNode } from "react";
import { Navigate } from "react-router";
import { appStore } from "../hooks/use-zustand";

interface IProps {
  children: ReactNode;
}

const PrivateRoutes: React.FC<IProps> = ({ children }) => {
  const { isLogged } = appStore();
  return <>{isLogged ? children : <Navigate to="/login" />}</>;
};

export default PrivateRoutes;
