import React from "react";
import * as S from "./user-list-buttons.styled";
import { useModal } from "../../../../hooks/useModal";
import { defaultTheme } from "../../../../utils/themes/theme";
import Button from "../../../../components/button/button";
import { BiPlus } from "react-icons/bi";
import { AiOutlineUser } from "react-icons/ai";
import { StyledH2 } from "../../../../utils/fonts/fonts.styled";
import AddPlayerModal from "../../add-player-modal/add-player-modal";
import AddQuestionsModal from "../../add-questions-modal/add-questions-modal";

interface IProps {
  sessionId: string;
  onPlay: () => void;
}

const UserListButtons: React.FC<IProps> = ({ sessionId, onPlay }) => {
  const { showModal, setShowModal } = useModal();
  const { showModal:showQuestionModal, setShowModal: setShowQuestionModal } = useModal();

  return (
    <S.Container>
      <S.ButtonContainer>
        <S.ButtonGroup>
          <Button
            className="action-button"
            background={defaultTheme.colors.yellow_200}
            backgrounHover={defaultTheme.colors.yellow_100}
            onClick={() => setShowModal(true)}
          >
            <BiPlus size={20} />
            <AiOutlineUser size={20} />
          </Button>
          <Button
            className="action-button"
            background={defaultTheme.colors.light_blue_300}
            backgrounHover={defaultTheme.colors.light_blue_200}
            onClick={() => setShowQuestionModal(true)}
          >
            <BiPlus size={20} />
          </Button>
        </S.ButtonGroup>
      </S.ButtonContainer>

      <S.ButtonContainer>
        <Button
          background={defaultTheme.colors.green_200}
          backgrounHover={defaultTheme.colors.green_100}
          onClick={onPlay}
        >
          <StyledH2>PLAY!</StyledH2>
        </Button>
      </S.ButtonContainer>

      {showModal && sessionId && (
        <AddPlayerModal
          sessionId={sessionId}
          onClose={() => setShowModal(false)}
        />
      )}
      {showQuestionModal && sessionId && (
        <AddQuestionsModal
          sessionId={sessionId}
          onClose={() => setShowQuestionModal(false)}
        />
      )}
    </S.Container>
  );
};

export default UserListButtons;
