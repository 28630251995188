import styled from "styled-components";
import { Container } from "../../../components/card/card.styled";

export const UserListContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 400px;
  row-gap: 10px;
`;

export const ListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin-block: 0px;
  padding-inline: 0px;
  row-gap: 10px;
  overflow-y: auto;
  padding: 10px;
  flex-grow: 1;
`;

export const Item = styled.li`
  display: flex;
  list-style-type: none;
`;
