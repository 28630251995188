import React, { useState } from "react";
import * as S from "./selectable-item-list.styled";
import { defaultTheme } from "../../utils/themes/theme";

interface ItemData {
  id: number;
  name: string;
}

interface IProps {
  items: ItemData[];
  onChange: (dataId: number) => void;
  selected: number;
}

const SelectableItemList: React.FC<IProps> = ({
  items,
  onChange,
  selected,
}) => {
  const handleItemClick = (itemId: number) => {
    onChange(itemId);
  };

  return (
    <S.ListContainer>
      {items.map((item) => (
        <S.Item
          key={item.id}
          onClick={() => handleItemClick(item.id)}
          $background={
            item.id === selected
              ? defaultTheme.colors.yellow_100
              : defaultTheme.colors.yellow_300
          }
        >
          {item.name}
        </S.Item>
      ))}
    </S.ListContainer>
  );
};

export default SelectableItemList;
