import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  height: 50px;
  box-sizing: border-box;
`;
