import React from "react";
import Card from "./card/card";
import * as S from "./game-page.styled";
import useGame from "./use-game";
import PenaltyModal from "./penalty-modal/penalty-modal";
import ImageLogo from "../../components/logoImage/logoImage";
import { defaultTheme } from "../../utils/themes/theme";
import { useModal } from "../../hooks/useModal";
import AddQuestionsModal from "../inscription-page/add-questions-modal/add-questions-modal";
import { useNavigate, useParams } from "react-router";
import Button from "../../components/button/button";
import { BiPlus } from "react-icons/bi";

const Game: React.FC = () => {
  const {
    combinedArray,
    users,
    userIndex,
    currentIndex,
    showModal,
    sessionId,
    setShowModal,
    handlePass,
    handleNextClick,
    getPenalty,
    skip,
    handleAddQandC,
  } = useGame();

  const { showModal: showQuestionModal, setShowModal: setShowQuestionModal } =
    useModal();

  const navigate = useNavigate();

  function handleExit(){
    navigate(-1)
  }
 
  function handleOnClose() {
    handleNextClick();
    setShowModal(false);
  }

  return (
    <S.Container>
      <ImageLogo isWhite width="200px" />

      {combinedArray.length > 0 && users.length > 0 && (
        <Card
          description={combinedArray[currentIndex].description}
          name={users[userIndex].name}
          onPass={() => handlePass()}
          onSuccess={() => handleNextClick()}
          onSkip={skip}
          onExit={handleExit }
        />
      )}

      <S.ButtonContainer>
        <Button
          className="action-button"
          background={defaultTheme.colors.light_blue}
          backgrounHover={defaultTheme.colors.light_blue_400}
          borderColor={defaultTheme.colors.white}
          onClick={() => setShowQuestionModal(true)}
        >
          <BiPlus size={20} />
        </Button>
      </S.ButtonContainer>

      {showModal && (
        <PenaltyModal onClose={handleOnClose} penalty={getPenalty() || 0} />
      )}

      {showQuestionModal && (
        <AddQuestionsModal
          sessionId={sessionId || "0"}
          onClose={() => setShowQuestionModal(false)}
          onSubmit={handleAddQandC}
        />
      )}
    </S.Container>
  );
};

export default Game;
