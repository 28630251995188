import React, { useState, useEffect } from "react";
import axios from "axios";
import * as S from "./user-list.styled";
import { defaultTheme } from "../../../utils/themes/theme";
import UserBubble from "../user-bubble/user-bubble";
import { useModal } from "../../../hooks/useModal";
import { useNavigate } from "react-router";
import EditUserModal from "../edit-user-modal/edit-user-modal";
import { IGameUser } from "../../../constants/user";
import DeleteUserModal from "../delete-user-modal/delete-user-modal";
import UserListButtons from "./action-buttons/user-list-buttons";

interface IProps {
  id: string;
}

const UserList: React.FC<IProps> = ({ id }) => {
  const [users, setUsers] = useState<IGameUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<IGameUser | undefined>(
    undefined
  );
  const navigate = useNavigate();

  const { showModal: showDeleteModal, setShowModal: setShowDeleteModal } =
    useModal();
  const { showModal: showEditModal, setShowModal: setShowEditModal } =
    useModal();

  useEffect(() => {
    const obtenerUsuarios = async () => {
      try {
        const response = await axios.get(`/users/${id}`);
        setUsers(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    const intervalId = setInterval(obtenerUsuarios, 4000);

    return () => clearInterval(intervalId);
  }, [id]);

  function handlePlay() {
    if (!(users.length === 0)) {
      navigate(`/session/${id}/play`);
    }
  }

  function handleOpenEditModal(id: number) {
    const user = users.find((user) => {
      return user.id === id;
    });
    setShowEditModal(true);

    if (user) {
      setSelectedUser(user);
      setShowEditModal(true);
    }
  }
  function handleDeleteModal(id: number) {
    const user = users.find((user) => {
      return user.id === id;
    });
    if (user) {
      setSelectedUser(user);
      setShowDeleteModal(true);
    }
  }

  function handleEditUser(newUser: IGameUser) {
    axios
      .put("/users/edit-player", {
        data: { user: newUser },
      })
      .then((data: any) => {
        //TODO add a toast and end the loading
        setShowEditModal(false);
      })
      .catch((error: any) => console.error(error));
  }

  function handleDeleteUser() {
    if (selectedUser) {
      axios
        .delete(`/users/${selectedUser.id}`)
        .then((data: any) => {
          //TODO add a toast and end the loading
          setShowDeleteModal(false);
        })
        .catch((error: any) => console.error(error));
    }
  }

  return (
    <S.UserListContainer
      $background={defaultTheme.colors.red_100}
      $borderColor={defaultTheme.colors.white}
    >
      <S.ListContainer>
        {users.map((user: any) => (
          <S.Item key={user.id + user.name}>
            <UserBubble
              user={{
                ...user,
                editUser: () => handleOpenEditModal(user.id),
                deleteUser: () => handleDeleteModal(user.id),
              }}
            />
          </S.Item>
        ))}
      </S.ListContainer>

      <UserListButtons onPlay={handlePlay} sessionId={id} />

      {showDeleteModal && (
        <DeleteUserModal
          onClose={() => setShowDeleteModal(false)}
          onDelete={handleDeleteUser}
          user={
            selectedUser || ({ name: " ", id: -1, session_id: -1 } as IGameUser)
          }
        />
      )}

      {showEditModal && (
        <EditUserModal
          onClose={() => setShowEditModal(false)}
          onSave={handleEditUser}
          user={
            selectedUser || ({ name: " ", id: -1, session_id: -1 } as IGameUser)
          }
        />
      )}
    </S.UserListContainer>
  );
};

export default UserList;
