import styled from 'styled-components';
import { Container } from '../card/card.styled';
import { fontLexendMega } from '../../utils/fonts/fonts.styled';

export const InputContainer = styled(Container)`
    display: flex;
    line-height: 28px;
    align-items: center;
    position: relative;
    width: 100%;
    box-sizing: border-box;
`;

export const InputStyled = styled.input`
    width: 100%;
    height: 30px;
    line-height: 28px;
    padding: 0 1rem;
    border: 2px solid transparent;
    border-radius: 8px;
    outline: none;
    background-color: #f3f3f4;
    color: #0d0c22;
    transition: .3s ease;
    ${fontLexendMega}

`;
   
   