import React, { useState } from "react";
import * as S from "./textArea.styled";
import { ITextAreaProps } from "../../constants/components";

const TextArea: React.FC<ITextAreaProps> = ({
  initialValue,
  onBlur,
  width,
  height,
  padding,
  fontSize,
  border,
  borderRadius,
  borderColor,
}) => {
  const [value, setValue] = useState<string>(initialValue || "");
  return (
    <S.StyledTextArea
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={onBlur}
      width={width}
      height={height}
      padding={padding}
      fontSize={fontSize}
      border={border}
      borderRadius={borderRadius}
      borderColor={borderColor}
    />
  );
};

export default TextArea;
