import React, { useState } from "react";
import * as S from './input.styled';

interface IProps {
    defaultValue?: string;
    onBlur:(newValue:string)=>void;
    type?: string;
    placeholder?: string;
    className?:string;
}

const Input: React.FC<IProps> = ({defaultValue='',onBlur, ...rest}) => {
  const [value, setValue] = useState("");
  
  function handleOnBlur(){
    onBlur(value);
  }

  return (
    <S.InputContainer>
        <S.InputStyled className="input" {...rest} value={value} onChange={(event) => setValue(event.target.value)} onBlur={handleOnBlur}/>
    </S.InputContainer>
  );
};

export default Input;



