import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  column-gap: 10px;
  .action-button {
    flex: 1;
  }
`;
