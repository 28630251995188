import { useEffect, useState } from "react";
import useGameFetching from "./use-game-fetching";
import { useModal } from "../../hooks/useModal";
import { useParams } from "react-router";

const useGame = () => {
  const [combinedArray, setCombinedArray] = useState<any>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [userIndex, setUserIndex] = useState(0);
  const [penaltiesMap, setPenaltiesMap] = useState<Map<number, number>>();
  const { showModal, setShowModal } = useModal();

  const { sessionId } = useParams();

  const { users, questions, generalChallenges, generalQuestions, challenges } =
    useGameFetching(sessionId);

  useEffect(() => {
    if (users.length) {
      createPenaltiesMap();
    }
  }, [users]);

  useEffect(() => {
    if (challenges && questions && generalQuestions && generalChallenges) {
      const combined = challenges
        .concat(questions)
        .concat(generalQuestions)
        .concat(generalChallenges);

      for (let i = combined.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [combined[i], combined[j]] = [combined[j], combined[i]];
      }

      setCombinedArray(combined);
    }
  }, [challenges, questions, generalChallenges, generalQuestions]);

  const handleNextClick = () => {
    const nextIndex = (currentIndex + 1) % combinedArray.length;
    setCurrentIndex(nextIndex);

    const uIndex = (userIndex + 1) % users.length;
    setUserIndex(uIndex);
  };

  const handlePass = () => {
    const userId = users[userIndex].id;
    if (penaltiesMap) {
      const penaltie = penaltiesMap.get(userId);
      if (penaltie !== undefined) {
        penaltiesMap.set(userId, penaltie + 1);
      }
    }
    setShowModal(true);
  };

  function createPenaltiesMap() {
    const newMap = new Map<number, number>(users.map((user) => [user.id, 0]));
    setPenaltiesMap(newMap);
  }

  function getPenalty() {
    const userId = users[userIndex].id;
    if (penaltiesMap) {
      return penaltiesMap.get(userId);
    }
    return 0;
  }

  function skip() {
    const nextIndex = (currentIndex + 1) % combinedArray.length;
    setCurrentIndex(nextIndex);
  }

  function handleAddQandC(data: { question?: string; challenge?: string }) {
    const newArray = [...combinedArray];
    if (data.question) {
      newArray.push({ description: data.question });
    }
    if (data.challenge) {
      newArray.push({ description: data.challenge });
    }
    setCombinedArray(newArray);
  }

  return {
    combinedArray,
    users,
    userIndex,
    currentIndex,
    showModal,
    sessionId,
    setShowModal,
    handlePass,
    handleNextClick,
    getPenalty,
    skip,
    handleAddQandC,
  };
};

export default useGame;
