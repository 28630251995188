import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 10px;
  box-sizing: border-box;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-grow: 1;
  column-gap: 10px;
  .action-button {
    flex: 1;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
`;
