import styled from "styled-components";
import { Container } from "../../../components/card/card.styled";

export const PenaltyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); // Añade un color de fondo semi-transparente para dar un efecto de oscurecimiento
  z-index: 100;
`;

export const ModalContainer = styled(Container)`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 335px;
  height: 150px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  padding: 8px;
  .ok-button{
    height: 20px;
    width: 100%;
  }
`;

export const Description = styled.h2`
  display: flex;
  justify-content: center;
  font-size: 20px;
  line-height: 1.5;
  flex-grow: 1;
  font-family: ${({ theme }) => theme.fonts.primary};
  hyphens: auto;
  font-style: italic;
`;
