import { useState } from "react";

export function useModal() {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState<any>(null);

  return {
    showModal,
    setShowModal,
    data,
    setData
  };
}
