import React from "react";
import InscriptionPage from "../pages/inscription-page/inscription-page";
import Game from "../pages/game-page/game-page";
import ManagePage from "../pages/manage-page/manage-page";
import { Route, Routes } from "react-router-dom";
import PrivateRoutes from "./private";
import HomePage from "../pages/home-page/home-page";
import Login from "../pages/login/login";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "../utils/themes/theme";
import AddPlayerPage from "../pages/add-player/add-player";

const AppRouter: React.FC = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/session/:sessionId" element={<InscriptionPage />} />
        <Route path="/session/:sessionId/play" element={<Game />} />
        <Route
          path="/session/:sessionId/add-player"
          element={<AddPlayerPage />}
        />
        <Route path="/login" element={<Login />} />
        <Route
          path="/manage"
          element={
            <PrivateRoutes>
              <ManagePage />
            </PrivateRoutes>
          }
        />

        {/* Ruta de manejo 404 */}
        <Route element={<HomePage />} />
      </Routes>
    </ThemeProvider>
  );
};

export default AppRouter;
